import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  useLoaderData,
  useRouteError,
} from '@remix-run/react'
import { json, LinksFunction, MetaFunction } from '@remix-run/node'
import type { LoaderFunction } from '@remix-run/router'
import { getAuthStateFromSession } from '~/auth/session.server'
import { AuthState } from '~/auth/AuthState'
import { AuthProvider } from '~/auth/AuthProvider'
import { faviconLinks } from './faviconLinks'

// the ?url is required for some reason when using vite
// https://remix.run/docs/en/main/styling/css
import stylesheet from '~/tailwind.css?url'
import { PageLoadingProgress } from '~/components/PageLoadingProgress'
import { useNonce } from '~/utils/nonce-provider'
import { setupMobiscroll } from '~/config/mobiscroll'
import { ErrorPage } from '~/components/ErrorPage'

setupMobiscroll()

export const meta: MetaFunction = () => [
  { charset: 'utf-8' },
  { title: 'Joviva admin' },
  { name: 'viewport', content: 'width=device-width,initial-scale=1' },
]

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: stylesheet },
  ...faviconLinks,
]

declare global {
  type Env = {
    NODE_ENV: string | undefined
    SENTRY_DSN: string | undefined
    SENTRY_ENVIRONMENT: string | undefined
    DEACTIVATED_FEATURES: string[]
  }

  interface Window {
    ENV: Env | undefined
  }
}
type LoaderReturnType = {
  ENV: Env | undefined
  authState: AuthState
}
export const loader: LoaderFunction = async ({ request }) => {
  const authState = await getAuthStateFromSession(request)

  return json<LoaderReturnType>({
    authState: authState,
    ENV: {
      NODE_ENV: process.env.NODE_ENV,
      SENTRY_DSN: process.env.SENTRY_DSN,
      SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT,
      DEACTIVATED_FEATURES: process.env.DEACTIVATED_FEATURES?.split(',') ?? [],
    },
  })
}

export const shouldRevalidate: ShouldRevalidateFunction = () => {
  return false
}

const Document = ({
  children,
  nonce,
}: {
  children: React.ReactNode
  nonce: string
}) => {
  return (
    <html lang={'de'}>
      <head>
        <Meta />
        <Links />
      </head>
      <body className='text-default'>
        <PageLoadingProgress />
        {children}
        {/* Manages scroll position for client-side transitions */}
        {/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
        <ScrollRestoration nonce={nonce} />

        {/* Script tags go here */}
        {/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
        <Scripts nonce={nonce} />

        {/* Sets up automatic reload when you change code */}
        {/* and only does anything during development */}
        {/* If you use a nonce-based content security policy for scripts, you must provide the `nonce` prop. Otherwise, omit the nonce prop as shown here. */}
        {/*{process.env.NODE_ENV === 'development' ? <LiveReload/> : null}*/}
      </body>
    </html>
  )
}

export const ErrorBoundary = () => {
  const error = useRouteError()
  const nonce = useNonce()

  return (
    <Document nonce={nonce}>
      <ErrorPage error={error} />
    </Document>
  )
}

export default function App() {
  const { ENV, authState } = useLoaderData() as LoaderReturnType
  const nonce = useNonce()
  return (
    <Document nonce={nonce}>
      {/* Child routes render here */}
      <AuthProvider initialState={authState}>
        <Outlet />
      </AuthProvider>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(ENV)}`,
        }}
        nonce={nonce}
      />
    </Document>
  )
}
