import * as Sentry from '@sentry/remix'
import { useLocation, useMatches } from '@remix-run/react'
import { useEffect } from 'react'
import { variables } from './variables.client'

const shouldSentryBeUsed = () => {
  if (!variables.SENTRY_DSN) {
    console.info('Sentry reporting disabled; set SENTRY_DSN to enable')
    return false
  }
  return true
}

export const initSentry = () => {
  if (!shouldSentryBeUsed()) {
    return false
  }
  console.info('Sentry reporting enabled')

  Sentry.init({
    dsn: variables.SENTRY_DSN,
    environment: variables.SENTRY_ENVIRONMENT,

    debug: variables.NODE_ENV === 'development',
    autoInstrumentRemix: true,

    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,

    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      // This is a known issue: https://github.com/getsentry/sentry-javascript/issues/9728
      // eslint-disable-next-line import/namespace
      Sentry.replayIntegration(),
    ],
  })
}
