/**
 * Collection of Tabler icons
 *
 * We decided to copy and paste icons for simplicity.
 * If you want to add more icons, please follow these steps:
 *
 * 1. Go to https://tabler.io/icons
 * 2. Select the icon you want and click "see more icon variants"
 * 3. Click "JSX code" and copy the code
 * 4. Create a new component in this file and paste the code
 * 5. Make sure you:
 *    - pass the className prop
 *    - replace hard-coded colors by "currentColor"
 *    - add a <title> element with a human readable name in German
 * */

export interface TablerIcon {
  className?: string
}

//TablerIcon: check
export const CheckMarkIcon = ({ className }: TablerIcon) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <title>Check</title>
    <path
      d='M4.91667 10L9.08333 14.1667L17.4167 5.83334'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

//TablerIcon: square-rounded-plus
export const AddIcon = ({ className }: TablerIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      className={className}
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z' />
      <path d='M15 12h-6' />
      <path d='M12 9v6' />
      <title>Plus</title>
    </svg>
  )
}

//TablerIcon: trash
export const TrashIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <title>Mülleimer</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M4 7l16 0' />
    <path d='M10 11l0 6' />
    <path d='M14 11l0 6' />
    <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
    <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
  </svg>
)

export const HandIcon = ({ className }: TablerIcon) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    <title>Hand</title>
    <g>
      <path
        d='M7.02439 7.43034L10.9288 3.52594C11.3826 3.07208 12.122 3.07208 12.5759 3.52594C13.0298 3.9798 13.0298 4.7192 12.5759 5.17306L13.673 4.07598C14.1268 3.62212 14.8662 3.62212 15.3201 4.07598C15.774 4.52984 15.774 5.26924 15.3201 5.7231L11.7644 9.27884L14.223 6.82018C14.6769 6.36632 15.4163 6.36632 15.8701 6.82018C16.324 7.27404 16.324 8.01344 15.8701 8.4673L13.4115 10.926L14.7701 9.56739C15.2239 9.11353 15.9633 9.11353 16.4172 9.56739C16.871 10.0212 16.871 10.7606 16.4172 11.2145L11.9627 15.6689C10.1443 17.4874 7.19271 17.4874 5.37426 15.6689L4.27719 14.5719L4.3914 14.6861C3.2823 13.577 2.80741 11.987 3.123 10.4511C3.14104 10.3609 3.16207 10.2707 3.1801 10.1775C3.27328 9.74472 3.71813 8.0946 4.52065 5.22716C4.69197 4.62001 5.31415 4.25933 5.9243 4.41262C6.57053 4.57493 7.02138 5.15202 7.02138 5.81929V7.43334'
        fill='currentColor'
      />
      <path
        d='M7.02439 7.43034L10.9288 3.52594C11.3826 3.07208 12.122 3.07208 12.5759 3.52594C13.0298 3.9798 13.0298 4.7192 12.5759 5.17306M12.5759 5.17306L10.1172 7.63172M12.5759 5.17306L13.673 4.07598C14.1268 3.62212 14.8662 3.62212 15.3201 4.07598C15.774 4.52984 15.774 5.26924 15.3201 5.7231L11.7644 9.27884L14.223 6.82018C14.6769 6.36632 15.4163 6.36632 15.8701 6.82018C16.324 7.27404 16.324 8.01344 15.8701 8.4673L13.4115 10.926L14.7701 9.56739C15.2239 9.11353 15.9633 9.11353 16.4172 9.56739C16.871 10.0212 16.871 10.7606 16.4172 11.2145L11.9627 15.6689C10.1443 17.4874 7.19271 17.4874 5.37426 15.6689L4.27719 14.5719L4.3914 14.6861C3.2823 13.577 2.80741 11.987 3.123 10.4511C3.14104 10.3609 3.16207 10.2707 3.1801 10.1775C3.27328 9.74472 3.71813 8.0946 4.52065 5.22716C4.69197 4.62001 5.31415 4.25933 5.9243 4.41262C6.57053 4.57493 7.02138 5.15202 7.02138 5.81929V7.43334'
        stroke='black'
        strokeWidth='0.762353'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <path
      d='M7.02429 7.43018L13.4144 13.8203'
      stroke='black'
      strokeWidth='0.762353'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.95575 8.34407L7.02155 7.43335'
      stroke='black'
      strokeWidth='0.762353'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

//TablerIcon: chevron-down
export const ChevronDownIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M6 9l6 6l6 -6' />
    <title>Pfeil nach unten</title>
  </svg>
)

//TablerIcon: chevron-left
export const ChevronLeftIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M15 6l-6 6l6 6' />
    <title>Pfeil nach links</title>
  </svg>
)

//TablerIcon: chevron-right
export const ChevronRightIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M9 6l6 6l-6 6' />
    <title>Pfeil nach rechts</title>
  </svg>
)

//TablerIcon: edit
export const EditIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
    <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
    <path d='M16 5l3 3' />
    <title>Stift</title>
  </svg>
)

// TablerIcon: Pencil
export const PencilIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <title>Stift</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4' />
    <path d='M13.5 6.5l4 4' />
  </svg>
)

//TablerIcon: logout
export const LogoutIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2' />
    <path d='M9 12h12l-3 -3' />
    <path d='M18 15l3 -3' />
  </svg>
)

//TablerIcon: alert-circle
export const AlertIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
    <path d='M12 8v4' />
    <path d='M12 16h.01' />
    <title>Ausrufezeichen</title>
  </svg>
)

//TablerIcon: info-circle
export const InfoIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0' />
    <path d='M12 9h.01' />
    <path d='M11 12h1v4h1' />
    <title>Info</title>
  </svg>
)

//TablerIcon: armchair-off
export const ArmchairOffIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M17 13a2 2 0 1 1 4 0v4m-2 2h-14a2 2 0 0 1 -2 -2v-4a2 2 0 1 1 4 0v2h8.036' />
    <path d='M5 11v-5a3 3 0 0 1 .134 -.89m1.987 -1.98a3 3 0 0 1 .879 -.13h8a3 3 0 0 1 3 3v5' />
    <path d='M6 19v2' />
    <path d='M18 19v2' />
    <path d='M3 3l18 18' />
    <title>Kein Termin verfügbar</title>
  </svg>
)

//TablerIcon: calendar-event
export const CalendarEventIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <title>Kalender Event</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z' />
    <path d='M16 3l0 4' />
    <path d='M8 3l0 4' />
    <path d='M4 11l16 0' />
    <path d='M8 15h2v2h-2z' />
  </svg>
)

//TablerIcon: calendar-plus
export const CalendarPlusIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth={2}
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <title>Termin hinzufügen</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5' />
    <path d='M16 3v4' />
    <path d='M8 3v4' />
    <path d='M4 11h16' />
    <path d='M16 19h6' />
    <path d='M19 16v6' />
  </svg>
)

//TablerIcon: calendar-month
export const CalendarIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M4 7a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12z' />
    <path d='M16 3v4' />
    <path d='M8 3v4' />
    <path d='M4 11h16' />
    <path d='M7 14h.013' />
    <path d='M10.01 14h.005' />
    <path d='M13.01 14h.005' />
    <path d='M16.015 14h.005' />
    <path d='M13.015 17h.005' />
    <path d='M7.01 17h.005' />
    <path d='M10.01 17h.005' />
  </svg>
)

// TablerIcon: calendar-cog
export const CalendarCogIcon = ({ className }: TablerIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      className={className}
    >
      <title>Kalender-Einstellungen</title>
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M12 21h-6a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5' />
      <path d='M16 3v4' />
      <path d='M8 3v4' />
      <path d='M4 11h16' />
      <path d='M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
      <path d='M19.001 15.5v1.5' />
      <path d='M19.001 21v1.5' />
      <path d='M22.032 17.25l-1.299 .75' />
      <path d='M17.27 20l-1.3 .75' />
      <path d='M15.97 17.25l1.3 .75' />
      <path d='M20.733 20l1.3 .75' />
    </svg>
  )
}

// TablerIcon: home-cog
export const HomeCogIcon = ({ className }: TablerIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      className={className}
    >
      <title>Standort Einstellungen</title>
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M9 21v-6a2 2 0 0 1 2 -2h1.6' />
      <path d='M20 11l-8 -8l-9 9h2v7a2 2 0 0 0 2 2h4.159' />
      <path d='M18 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
      <path d='M18 14.5v1.5' />
      <path d='M18 20v1.5' />
      <path d='M21.032 16.25l-1.299 .75' />
      <path d='M16.27 19l-1.3 .75' />
      <path d='M14.97 16.25l1.3 .75' />
      <path d='M19.733 19l1.3 .75' />
    </svg>
  )
}

export const DiamondIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    width='16'
    height='16'
    viewBox='0 0 24 24'
    strokeWidth='2'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <title>Brand</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M18.316 5h-12.632l-3.418 4.019a1.089 1.089 0 0 0 .019 1.447l9.714 10.534l9.715 -10.49a1.09 1.09 0 0 0 .024 -1.444l-3.422 -4.066z' />
    <path d='M9 11l3 3l3 -3' />
  </svg>
)

//TablerIcon: hotel-service
export const RoomIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <title>Raum</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M8.5 10a1.5 1.5 0 0 1 -1.5 -1.5a5.5 5.5 0 0 1 11 0v10.5a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2c0 -1.38 .71 -2.444 1.88 -3.175l4.424 -2.765c1.055 -.66 1.696 -1.316 1.696 -2.56a2.5 2.5 0 1 0 -5 0a1.5 1.5 0 0 1 -1.5 1.5z' />
  </svg>
)

//TablerIcon: user-circle
export const UserIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    width='16'
    height='16'
    viewBox='0 0 24 24'
    strokeWidth='2'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <title>Anwender</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' />
    <path d='M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0' />
    <path d='M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855' />
  </svg>
)

//TablerIcon: phone
export const PhoneIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    width='16'
    height='16'
    viewBox='0 0 24 24'
    strokeWidth='2'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <title>Telefon</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2' />
  </svg>
)

//TablerIcon: mail
export const MailIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    width='16'
    height='16'
    viewBox='0 0 24 24'
    strokeWidth='2'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <title>E-Mail</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z' />
    <path d='M3 7l9 6l9 -6' />
  </svg>
)

//TablerIcon: align-justified
export const NoteIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    width='16'
    height='16'
    viewBox='0 0 24 24'
    strokeWidth='2'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <title>Notiz</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M4 6l16 0' />
    <path d='M4 12l16 0' />
    <path d='M4 18l12 0' />
  </svg>
)

//TablerIcon: x
export const CloseIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    className={className}
  >
    <title>Schließen</title>
    <path
      d='M18 6L6 18M6 6L18 18'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

//TablerIcon: copy
export const CopyIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    width='16'
    height='16'
    viewBox='0 0 24 24'
    strokeWidth='2'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <title>Kopieren</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z' />
    <path d='M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1' />
  </svg>
)

//TablerIcon: address-book
export const AddressBookIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='32'
    viewBox='0 0 24 24'
    stroke='currentColor'
    fill='none'
    strokeWidth='1.5'
    className={className}
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z' />
    <path d='M10 16h6' />
    <path d='M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
    <path d='M4 8h3' />
    <path d='M4 12h3' />
    <path d='M4 16h3' />
  </svg>
)

export const AdjustmentsHorizontalIcon = ({
  className,
}: {
  className?: string
}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    strokeWidth='1.5'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
    <path d='M4 6l8 0' />
    <path d='M16 6l4 0' />
    <path d='M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
    <path d='M4 12l2 0' />
    <path d='M10 12l10 0' />
    <path d='M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
    <path d='M4 18l11 0' />
    <path d='M19 18l1 0' />
  </svg>
)

export const WheelChairIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    className={className}
  >
    <title>Rollstuhl</title>
    <path
      d='M13.8833 15.4373C13.8833 16.2939 14.5776 16.9913 15.4372 16.9913C16.2969 16.9913 16.9912 16.2969 16.9912 15.4373C16.9912 14.5777 16.2969 13.8834 15.4372 13.8834C14.5776 13.8834 13.8833 14.5777 13.8833 15.4373Z'
      fill='currentColor'
      stroke='#1E1E1E'
      strokeWidth='0.762353'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.4342 13.8834C15.4342 12.5969 14.3913 11.554 13.1048 11.554H10.4658'
      stroke='#1E1E1E'
      strokeWidth='0.762353'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.01196 3.01176H3.78743C4.64405 3.01176 5.34137 3.70608 5.34137 4.56571V9.22454'
      stroke='black'
      strokeWidth='0.762353'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.34131 6.89514H13.8835'
      stroke='black'
      strokeWidth='0.762353'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.3296 6.89514V11.554'
      stroke='black'
      strokeWidth='0.762353'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M3.01196 13.1083C3.01196 15.2513 4.74925 16.9916 6.89532 16.9916C9.04138 16.9916 10.7787 15.2543 10.7787 13.1083C10.7787 10.9622 9.04138 9.22491 6.89532 9.22491C4.74925 9.22491 3.01196 10.9622 3.01196 13.1083Z'
      fill='currentColor'
    />
    <path d='M6.8863 13.1083H6.89532Z' fill='currentColor' />
    <path
      d='M6.8863 13.1083H6.89532M3.01196 13.1083C3.01196 15.2513 4.74925 16.9916 6.89532 16.9916C9.04138 16.9916 10.7787 15.2543 10.7787 13.1083C10.7787 10.9622 9.04138 9.22491 6.89532 9.22491C4.74925 9.22491 3.01196 10.9622 3.01196 13.1083Z'
      stroke='black'
      strokeWidth='0.762353'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M6.82373 13.0367V13.0457Z' fill='currentColor' />
    <path
      d='M6.82373 13.0367V13.0457'
      stroke='black'
      strokeWidth='1.05882'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const ChurchIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    className={className}
  >
    <title>Kirche</title>
    <path
      d='M2.9187 17.0814H17.0815ZM4.47791 17.0814V4.49222C4.47791 4.07486 4.64414 3.6746 4.94003 3.37948C5.23592 3.08437 5.63723 2.91857 6.05569 2.91857H13.9445C14.363 2.91857 14.7643 3.08437 15.0602 3.37948C15.3561 3.6746 15.5223 4.07486 15.5223 4.49222V17.0814M7.6613 17.0814V13.9197C7.6613 13.5004 7.82558 13.0983 8.11798 12.8018C8.41037 12.5054 8.80701 12.3388 9.22049 12.3388H10.7797C11.1932 12.3388 11.5899 12.5054 11.8823 12.8018C12.1746 13.0983 12.3389 13.5004 12.3389 13.9197V17.0814'
      fill='currentColor'
    />
    <path
      d='M2.9187 17.0814H17.0815M4.47791 17.0814V4.49222C4.47791 4.07486 4.64414 3.6746 4.94003 3.37948C5.23592 3.08437 5.63723 2.91857 6.05569 2.91857H13.9445C14.363 2.91857 14.7643 3.08437 15.0602 3.37948C15.3561 3.6746 15.5223 4.07486 15.5223 4.49222V17.0814M7.6613 17.0814V13.9197C7.6613 13.5004 7.82558 13.0983 8.11798 12.8018C8.41037 12.5054 8.80701 12.3388 9.22049 12.3388H10.7797C11.1932 12.3388 11.5899 12.5054 11.8823 12.8018C12.1746 13.0983 12.3389 13.5004 12.3389 13.9197V17.0814'
      stroke='black'
      strokeWidth='0.689123'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.35299 4.87207C9.13302 4.87207 8.95472 5.05039 8.95472 5.27035V6.39056H7.83456C7.6146 6.39056 7.43628 6.56887 7.43628 6.78884V8.08327C7.43628 8.3032 7.6146 8.48158 7.83456 8.48158H8.95472V9.60171C8.95472 9.82168 9.13302 9.99998 9.35299 9.99998H10.6475C10.8674 9.99998 11.0458 9.82168 11.0458 9.60171V8.48158H12.1659C12.3859 8.48158 12.5642 8.3032 12.5642 8.08327V6.78884C12.5642 6.56887 12.3859 6.39056 12.1659 6.39056H11.0458V5.27035C11.0458 5.05039 10.8674 4.87207 10.6475 4.87207H9.35299Z'
      stroke='black'
      strokeWidth='0.689123'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const CareIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    className={className}
  >
    <title>Pflege</title>
    <path
      d='M8.84738 16.025H5.67091L3.55326 13.9074C3.04503 13.3992 2.91797 12.425 2.91797 12.0015V7.76622C2.98856 7.41328 3.29915 6.7074 3.97679 6.7074C4.65444 6.7074 4.96503 7.55446 5.03562 7.97799V10.3074L5.24738 10.7309C5.31797 10.7309 5.5015 10.6886 5.67091 10.5192C5.84032 10.3498 6.44738 10.5898 6.72973 10.7309L8.63562 12.6368C8.97444 12.9756 8.91797 15.0368 8.84738 16.025Z'
      fill='currentColor'
    />
    <path
      d='M11.2303 16.025H14.4067L16.5244 13.9074C17.0326 13.3992 17.1597 12.425 17.1597 12.0015V7.76622C17.0891 7.41328 16.7785 6.7074 16.1008 6.7074C15.4232 6.7074 15.1126 7.55446 15.042 7.97799V10.3074L14.8303 10.7309C14.7597 10.7309 14.5761 10.6886 14.4067 10.5192C14.2373 10.3498 13.6303 10.5898 13.3479 10.7309L11.442 12.6368C11.1032 12.9756 11.1597 15.0368 11.2303 16.025Z'
      fill='currentColor'
    />
    <path
      d='M5.65133 15.9633C5.65133 15.9633 4.55594 14.867 3.55893 13.869C3.14896 13.4588 2.9187 12.9023 2.9187 12.322V7.75838C2.9187 7.15419 3.40808 6.6644 4.01175 6.6644C4.61543 6.6644 5.1048 7.15419 5.1048 7.75838V11.0403'
      stroke='black'
      strokeWidth='0.689123'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.3491 15.9633C14.3491 15.9633 15.4446 14.867 16.4416 13.869C16.8515 13.4588 17.0817 12.9023 17.0817 12.322V7.75838C17.0817 7.15419 16.5924 6.6644 15.9887 6.6644C15.385 6.6644 14.8957 7.15419 14.8957 7.75838V11.0403'
      stroke='black'
      strokeWidth='0.689123'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.10404 13.0403L5.33113 12.2667C4.90427 11.8395 4.90427 11.1468 5.33113 10.7196C5.758 10.2924 6.45008 10.2924 6.87694 10.7196L8.29018 12.134C8.70008 12.5444 8.93041 13.1008 8.93041 13.6812V15.9632'
      stroke='black'
      strokeWidth='0.689123'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.896 13.0403L14.6689 12.2667C15.0957 11.8395 15.0957 11.1468 14.6689 10.7196C14.242 10.2924 13.5499 10.2924 13.123 10.7196L11.7098 12.134C11.2998 12.5444 11.0696 13.1008 11.0696 13.6812V15.9632'
      stroke='black'
      strokeWidth='0.689123'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.0213 9.354L7.31966 6.9048C5.85138 5.43527 8.00975 2.61377 10.0213 4.89644C12.0328 2.61377 14.1814 5.44507 12.7229 6.9048L10.0213 9.354Z'
      fill='currentColor'
      stroke='black'
      strokeWidth='0.689123'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const ProsthesisIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='22'
    viewBox='0 0 22 22'
    fill='none'
    className={className}
  >
    <title>Prothese</title>
    <path
      d='M8.20699 3.37646L11.1416 6.97346C11.804 7.63581 11.8007 8.70802 11.1416 9.37036C10.4793 10.0327 9.40708 10.0294 8.74473 9.37036L5.1543 6.42588L8.20699 3.37975V3.37646Z'
      fill='currentColor'
      stroke='#1E1E1E'
      strokeWidth='0.762353'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3015 10.154V14.0035'
      stroke='#1E1E1E'
      strokeWidth='0.762353'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.71815 14.0035H11.9413C12.0922 14.0035 12.2299 14.0822 12.3053 14.2134L12.7545 14.9839C12.9283 15.2856 13.2201 15.4987 13.5579 15.5807L15.5383 16.0529C16.3023 16.2332 16.8401 16.9152 16.8401 17.6989V18.1973C16.8401 18.4301 16.6499 18.6203 16.4171 18.6203H8.71815C8.48534 18.6203 8.29517 18.4301 8.29517 18.1973V14.4232C8.29517 14.1904 8.48534 14.0002 8.71815 14.0002V14.0035Z'
      fill='currentColor'
      stroke='#1E1E1E'
      strokeWidth='0.762353'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.2986 16.0103H10.3076'
      stroke='black'
      strokeWidth='1.05882'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

// TablerIcon: door-exit
export const DoorExitIcon = ({ className }: TablerIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      width='32'
      height='32'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='currentColor'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M13 12v.01' />
      <path d='M3 21h18' />
      <path d='M5 21v-16a2 2 0 0 1 2 -2h7.5m2.5 10.5v7.5' />
      <path d='M14 7h7m-3 -3l3 3l-3 3' />
    </svg>
  )
}

// TablerIcon: alarm
export const AlarmIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 24 24'
    strokeWidth='2'
    stroke='currentColor'
    fill='none'
    strokeLinecap='round'
    strokeLinejoin='round'
    className={className}
  >
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M12 13m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0' />
    <path d='M12 10l0 3l2 0' />
    <path d='M7 4l-2.75 2' />
    <path d='M17 4l2.75 2' />
  </svg>
)

// TablerIcon: id-badge-2
export const HealthInsuranceIcon = ({ className }: TablerIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      className={className}
    >
      <title>Krankenkasse</title>
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M7 12h3v4h-3z' />
      <path d='M10 6h-6a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h16a1 1 0 0 0 1 -1v-12a1 1 0 0 0 -1 -1h-6' />
      <path d='M10 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z' />
      <path d='M14 16h2' />
      <path d='M14 12h4' />
    </svg>
  )
}

// TablerIcon: thumb-up
export const ThumbUpFilledIcon = ({ className }: TablerIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='white'
      stroke='currentColor'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      className={className}
    >
      <title>Daumen hoch</title>
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3' />
    </svg>
  )
}

// TablerIcon: select
export const SelectIcon = ({ className }: TablerIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      className={className}
    >
      <title>Auswahl icon</title>
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M8 9l4 -4l4 4' />
      <path d='M16 15l-4 4l-4 -4' />
    </svg>
  )
}

// TablerIcon: adjustments-alt
export const FilterIcon = ({ className }: TablerIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      viewBox='0 0 24 24'
      strokeWidth={2}
      stroke='currentColor'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
      className={className}
    >
      <title>Auswahl Filter</title>
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M4 8h4v4h-4z' />
      <path d='M6 4l0 4' />
      <path d='M6 12l0 8' />
      <path d='M10 14h4v4h-4z' />
      <path d='M12 4l0 10' />
      <path d='M12 18l0 2' />
      <path d='M16 5h4v4h-4z' />
      <path d='M18 4l0 1' />
      <path d='M18 9l0 11' />
    </svg>
  )
}

// TablerIcon: circle-dashed
export const DashedCircle = ({ className }: TablerIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='none'
      width={24}
      height={24}
      strokeWidth={2}
      strokeLinejoin='round'
      strokeLinecap='round'
      stroke='currentColor'
      className={className}
    >
      <title>Circle dashed</title>
      <path d='M8.56 3.69a9 9 0 0 0 -2.92 1.95'></path>
      <path d='M3.69 8.56a9 9 0 0 0 -.69 3.44'></path>
      <path d='M3.69 15.44a9 9 0 0 0 1.95 2.92'></path>
      <path d='M8.56 20.31a9 9 0 0 0 3.44 .69'></path>
      <path d='M15.44 20.31a9 9 0 0 0 2.92 -1.95'></path>
      <path d='M20.31 15.44a9 9 0 0 0 .69 -3.44'></path>
      <path d='M20.31 8.56a9 9 0 0 0 -1.95 -2.92'></path>
      <path d='M15.44 3.69a9 9 0 0 0 -3.44 -.69'></path>
    </svg>
  )
}

// TablerIcon: circle-check
export const CheckedCircle = ({ className }: TablerIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='none'
      width={24}
      height={24}
      strokeWidth={2}
      strokeLinejoin='round'
      strokeLinecap='round'
      stroke='currentColor'
      className={className}
    >
      <title>Circle checked</title>
      <path d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0'></path>
      <path d='M9 12l2 2l4 -4'></path>
    </svg>
  )
}

// TablerIcon: circle-check-x
export const DashedCircleX = ({ className }: TablerIcon) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='none'
      width={24}
      height={24}
      strokeWidth={2}
      strokeLinejoin='round'
      strokeLinecap='round'
      stroke='currentColor'
      className={className}
    >
      <title>Circle dashed x</title>
      <path d='M8.56 3.69a9 9 0 0 0 -2.92 1.95'></path>
      <path d='M3.69 8.56a9 9 0 0 0 -.69 3.44'></path>
      <path d='M3.69 15.44a9 9 0 0 0 1.95 2.92'></path>
      <path d='M8.56 20.31a9 9 0 0 0 3.44 .69'></path>
      <path d='M15.44 20.31a9 9 0 0 0 2.92 -1.95'></path>
      <path d='M20.31 15.44a9 9 0 0 0 .69 -3.44'></path>
      <path d='M20.31 8.56a9 9 0 0 0 -1.95 -2.92'></path>
      <path d='M15.44 3.69a9 9 0 0 0 -3.44 -.69'></path>
      <path d='M14 14l-4 -4'></path>
      <path d='M10 14l4 -4'></path>
    </svg>
  )
}

// TablerIcon: external-link
export const ExternalLinkIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    width={24}
    height={24}
    strokeWidth={2}
    className={className}
  >
    <title>External Link</title>
    <path d='M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6'></path>
    <path d='M11 13l9 -9'></path>
    <path d='M15 4h5v5'></path>
  </svg>
)

// TablerIcon: eye
export const EyeIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    width={24}
    height={24}
    strokeWidth={2}
    className={className}
  >
    <title>Ausblenden</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
    <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
  </svg>
)

// Tabler-icon: eye-off
export const EyeOffIcon = ({ className }: TablerIcon) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeLinecap='round'
    strokeLinejoin='round'
    width={24}
    height={24}
    strokeWidth={2}
    className={className}
  >
    <title>Einblenden</title>
    <path stroke='none' d='M0 0h24v24H0z' fill='none' />
    <path d='M10.585 10.587a2 2 0 0 0 2.829 2.828' />
    <path d='M16.681 16.673a8.717 8.717 0 0 1 -4.681 1.327c-3.6 0 -6.6 -2 -9 -6c1.272 -2.12 2.712 -3.678 4.32 -4.674m2.86 -1.146a9.055 9.055 0 0 1 1.82 -.18c3.6 0 6.6 2 9 6c-.666 1.11 -1.379 2.067 -2.138 2.87' />
    <path d='M3 3l18 18' />
  </svg>
)
